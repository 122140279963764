@import 'scss/components_base';

main .container {
  position: relative;
  background-color: var(--color-primary) !important;
  color: var(--color-white);
  // min-height: 200vh;
}

.pageHeader {
  @include container;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  justify-content: space-around;
  > * {
    @include gutters;
  }
  .mollerArchitects {
    @include _sm {
      width: columns(4);
      height: auto;
    }
    @include md_ {
      width: columns(9);
      height: auto;
      max-height: 55vh;
      @include gutters;
    }
    margin-top: rem-property(40px);
    margin-bottom: rem-property(40px);
    // position: relative;
    // left: -1.8%;
  }
  .introduction {
    @include md_ {
      max-width: columns(8);
    }
    margin-bottom: rem-property(30px);
    @include type_heading_1;
    p {
      margin: 0;
    }
  }
  @include md_ {
    @include fixed;
    top: var(--header-height);
    left: 0;
    right: 0;
    .mollerArchitects {
      will-change: transform;
      transform: translate3d(0, 0, 0);
    }
    .introduction {
      will-change: transform;
      transform: translate3d(0, 0, 0);
    }
  }
}

.pageFooter {
  @include _sm {
    @include gutters(padding, $multiplier: 2);
    @include gutters(padding-top, $multiplier: 2);
    @include gutters(padding-bottom, $multiplier: 2);
    .rect {
      min-width: 100%;
    }
  }
  @include md_ {
    @include fixed;
    top: 100vh;
    left: 0;
    right: 0;

    height: 100vh;
    min-width: 100%;
    @include gutters(padding, $multiplier: 2);
    @include gutters(padding-top, $multiplier: 2);
    @include gutters(padding-bottom, $multiplier: 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
}
